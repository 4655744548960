





















































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import WorkSession from '@/models/WorkSession';
import {JobStatusIdentification} from '@/misc/JobStatusIdentification';
import Job from '@/models/Job';
import moment from 'moment';
import {showTimes, TimeDuration} from '@/helper/TimeDuration';
import CleanTime from '@/models/CleanTime';
import {WEEKDAYS} from '@/Constants';
import Customer from '@/models/Customer';
import Location from '@/models/Location';
import CleanTimeOccurrence from '@/models/CleanTimeOccurrence';
import {Permission} from '@/misc/enums/permission.enum';
import Address from '@/models/Address';
import { namespace } from 'vuex-class';
import { jobStoreGetter } from '@/stores/job.store';
import { JobColors } from '@/misc/JobStatusColors';

const JobStore = namespace('job');

@Component({
  computed: {
    Permission() {
      return Permission;
    },
  },
  components: {},
})
export default class SideBarDetailsComponent extends Vue {

  @JobStore.Getter(jobStoreGetter.JOB_COLORS)
  public jobColors!: JobColors;

  @Prop({default: () => new Job()})
  public job?: Job | WorkSession;
  @Prop()
  public loadedLocation?: Location;
  @Prop()
  public loadedCustomer?: Customer;
  @Prop()
  public selectedCustomer?: Customer;
  @Prop()
  public selectedLocation?: Location;
  @Prop()
  public selectedWorksession?: WorkSession;
  @Prop({default: true})
  public isWorksession?: boolean;
  @Prop({default: true})
  public deletable!: boolean;
  /**
   *  Decides whether or not the object should be editable
   */
  @Prop({default: true})
  public editable!: boolean;
  /**
   *  Decides whether or not the object should be copyable
   */
  @Prop({default: true})
  public copyable!: boolean;

  private get locationAddress() {
    return this.loadedLocation?.address ?? this.loadedCustomer?.address ?? new Address();
  }

  public getStatus() {
    return JobStatusIdentification.identify(this.job! as Job);
  }

  public getStatusStyle() {
    const color = this.jobColors[this.getStatus()].colorCode;

    // Convert hex color to RGB
    const r = parseInt(color.substring(1, 3), 16); // Extract and parse the red component
    const g = parseInt(color.substring(3, 5), 16); // Extract and parse the green component
    const b = parseInt(color.substring(5, 7), 16); // Extract and parse the blue component

    // Calculate brightness using a standard formula
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Determine text color based on brightness
    const textColor = brightness > 125 ? 'black' : 'white';

    return `background-color: ${color}; color: ${textColor};`;
  }


  public jobisover: boolean = true;

  public mounted() {
    this.jobisover = moment().isAfter(moment((this.job!.cleanTimeOccurrence as CleanTimeOccurrence)?.end));
  }

  public showTime = (time: TimeDuration) => showTimes(time);

  public get getCleanTimeFrequency(): string[] {
    const cleanTime = (this.isWorksession ? this.selectedWorksession?.cleanTime : this.job?.cleanTime) as CleanTime;

    let tmp = cleanTime.byWeekDay;

    if (tmp === null || tmp.length >= 7) {
      tmp = WEEKDAYS.map((val: string) => val);
    }

    return tmp.map((day) =>
        this.$t('GENERAL.DAYS_SHORT.' + day.toUpperCase()).toString());
  }

  /**
   * Formats two times to a time format hh:mm
   * @param startTime The planned start of the Session
   * @param duration The duration of the workSession
   */

  public getFormattedTime(startTime: string, duration: number) {
    // Get Moment Object from start and end
    const start = moment(startTime);
    const end = moment(startTime).add(duration);

    // Date is not valid, the job wasn't worked on
    if (!start.isValid() || !end.isValid()) {
      return this.$t('TIMETRACKING.MESSAGES.NOT_DETERMINED').toString();
    }

    // Return Time representation
    return `${start.format('HH:mm')} ${this.$t('GENERAL.CLOCK').toString()}` +
      ` - ` +
      `${end.format('HH:mm')} ${this.$t('GENERAL.CLOCK').toString()}`;
  }

  public getbetweenday(startTime: string, endTime: string) {
    // Get Moment Object from start and end
    const start = moment(startTime);
    const end = moment(endTime);

    // Date is not valid, the job wasnt worked on
    // Return Time representation
    return `${start.format('DD.MM.yy')} - ${end.format('DD.MM.yy')}`;
  }

  private onLocationClick() {
    this.$router.push({
      name: 'locationDashboard',
      params: {
        companyId: this.loadedLocation!.companyId,
        customerId: this.loadedLocation!.customerId,
        locationId: this.loadedLocation!.id!,
      },
    });
  }

  private onCustomerClick() {
    this.$router.push({
      name: 'customerDashboard',
      params: {
        companyId: this.loadedLocation!.companyId,
        customerId: this.loadedLocation!.customerId,
      },
    });
  }
}
